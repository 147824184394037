import React from 'react'
import { useProductsContext } from '../context/products_context'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Error from './Error'
import Loading from './Loading'
import Product from './Product'
import ListView from './ListView'
import { data } from '../utils/constants'

const FeaturedProducts = ({ setMain }) => {
  // const {
  //   products_loading: loading,
  //   products_error: error,
  //   featured_products: featured,
  // } = useProductsContext()
  // if (loading) {
  //   return <Loading />
  // }
  // if (error) {
  //   return <Error />
  // }
  return (
    <Wrapper className="section">
      <div className="section-center featured">
        {data.map((product) => {
          return <Product setMain={setMain} key={product.id} {...product} />
        })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  /* background: var(--clr-grey-10); */
  .featured {
    margin: 4rem auto;
    display: grid;
    gap: 2.5rem;
    img {
      /* height: 225px; */
      height: 165px;
    }
  }
  .btn {
    display: block;
    width: 148px;
    margin: 0 auto;
    text-align: center;
  }
  @media (min-width: 768px) {
    .featured {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  }
`

export default FeaturedProducts
